@font-face {
  font-family: "Avenir";
  src: local("Avenir Light"), local("Avenir-Light"),
    url("/public/fonts/Avenir-Light.woff") format("woff"),
    /* Modern Browsers */ url("/public/fonts/Avenir-Light.woff2")
      format("woff2"); /* Legacy Browsers */
  font-weight: 300;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir Roman"), local("Avenir-Roman"),
    url("/public/fonts/Avenir-Roman.woff") format("woff"),
    url("/public/fonts/Avenir-Roman.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir Medium"), local("Avenir-Medium"),
    url("/public/fonts/Avenir-Medium.woff") format("woff"),
    url("/public/fonts/Avenir-Medium.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir Heavy"), local("Avenir-Heavy"),
    url("/public/fonts/Avenir-Heavy.woff") format("woff"),
    url("/public/fonts/Avenir-Heavy.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir Black"), local("Avenir-Black"),
    /* url('/public/fonts/Avenir-Bla.woff') format('woff'), */
      url("/public/fonts/Avenir-Black.woff2") format("woff2");
  font-weight: 900;
}

body {
  /* font-family: 'Kite One', sans-serif; */
  font-family: "Avenir", sans-serif !important;
  /* font-family: 'Sen', sans-serif; */
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  /* font-family: "Manrope"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-svg-text {
  font-size: 28px;
  font-weight: 800;
  width: 5px !important;
}

/* chakra-slider__filled-track  */
.css-1m7iqxj {
  background: transparent !important;
}

.css-1v8vhzx {
  background-color: #fff50a !important;
}

.chakra-checkbox__control {
  /* border-radius: 50% !important; */
  padding: 5px;
}

.custom-checkbox input:checked + .chakra-checkbox__control {
  background-color: black; /* Change this to your desired background color */
  border-color: black; /* Change this to match the background color if needed */
}
.css-0 {
  /* background-color: #FFF50A !important; */
}
.custom-svg-sec5 {
  font-size: 102px;
  font-weight: 800;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: auto;
  /* height: 2.5rem; */
}

.fade-container {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}

.resp-tabs {
  /* background-color: #FFF50A !important; */
}
.resp-tabs-hidden {
  display: none !important;
}
.mobile_active {
  display: none !important;
}
@media only screen and (max-width: 600px) {
  .resp-tabs {
    display: none !important;
  }

  .avatar-resp {
    display: none !important;
  }

  .mobile_inactive {
    display: none !important;
  }

  .resp-tabs-hidden {
    display: block !important;
  }

  .mobile_active {
    display: block !important;
  }

  .resp-svg {
    display: none !important;
  }
}

.dnd {
  touch-action: none;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 120px;
  height: 20px;
  border-radius: 20px;
  background: linear-gradient(yellow 0 0) 0/0% no-repeat lightblue;
  animation: l2 2s infinite steps(10);
}
@keyframes l2 {
  100% {
    background-size: 110%;
  }
}
